<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-4">
                        <h1 class="m-0 text-dark">{{ $t('globalTrans.all_order') }}</h1>
                        <img id="logoId" src="../../public/images/logo.png" style="width:40px; height:40px; display:none;">
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <form v-on:keyup.enter="searchOrder">
                                    <div class="row">
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="contact">{{ $t('globalTrans.contact') }}</label>
                                                <input type="text" id="contact" v-model="search.contact" placeholder="Name, Mobile, Address" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-1">
                                            <div class="form-group">                                            
                                                <label for="sku">{{ $t('globalTrans.order_id') }}</label>
                                                <input type="text" id="sku" v-model="search.sku" placeholder="Order ID" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-1">
                                            <div class="form-group">                                            
                                                <label for="ref_id">{{ $t('globalTrans.ref_id') }}</label>
                                                <input type="text" id="ref_id" v-model="search.ref_id" placeholder="Ref. ID" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="status">Completed / Incompleted</label>      
                                                <select class="form-control" v-model="search.status_head">
                                                    <option value="0">All</option>
                                                    <option value="1">Completed</option>
                                                    <option value="2">Incompleted</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="status">{{ $t('globalTrans.status') }}</label>      
                                                <v-select name="status"
                                                    multiple
                                                    v-model="search.status"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= riderStatusList
                                                    :placeholder="$t('globalTrans.select')"
                                                /> 
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="start_date">{{ $t('globalTrans.startDate') }}</label>
                                                <input type="date" id="start_date" v-model="search.start_date" class="form-control">                                                
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="end_date">{{ $t('globalTrans.endDate') }}</label>
                                                <input type="date" id="end_date" v-model="search.end_date" class="form-control">                                                
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group" style="margin-bottom:0px;">
                                            <label>
                                                <input type="checkbox" @click="selectAll" v-model="allSelected">
                                                <p style="display: inline-block; margin-left: 4px; font-size: 15px;">{{ $t('globalTrans.select_all') }} | {{ $t('globalTrans.selected_parcel') }} ({{ totalSelected }} / {{ totalOrder }}) </p>
                                            </label>
                                            <button class="btn btn-info btn-sm ml-2 mr-2" type="submit" @click="showActionPanelModal"><i class="fa fa-check"></i> {{ $t('globalTrans.take_action') }}</button>
                                            <button class="btn btn-info btn-sm mr-2" type="submit" @click.prevent="searchOrder"><i class="fa fa-search"></i> {{ $t('globalTrans.search') }}</button>                                        
                                            <button class="btn btn-warning btn-sm" @click.prevent="reload"><i class="fa fa-sync-alt"></i></button>
                                            <slot v-if="loading">
                                                <div class="ml-4 spinner-border spinner-border-sm text-success" role="status"></div>
                                            </slot>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-12 summary">
                                        <p class="mr-10">Order : <b>{{ summary ? summary.total_order : 0 }}</b></p>
                                        <p class="mr-10">(Completed: <b>{{ summary ? summary.completed : 0 }}</b> Incompleted: <b>{{ summary ? summary.incompleted : 0 }}</b>)</p>
                                        <p class="mr-10">Amount : <b>{{ summary ? summary.total_price : 0 }}</b></p>
                                        <p class="mr-10">Collected : <b>{{ summary ? summary.total_collected : 0 }}</b></p>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">                                
                                <b-overlay :show="loading">
                                    <div class="table-responsive">
                                        <div style="width:100%; overflow-x:auto;">
                                            <table id="orderTable" class="table table-bordered table-striped table-sm text-center">
                                                <thead>
                                                    <tr class="text-center">
                                                        <th>{{ $t('globalTrans.order_id') }}</th>
                                                        <th>{{ $t('globalTrans.merchant') }}</th>
                                                        <th>{{ $t('globalTrans.customer') }}</th>
                                                        <th>{{ $t('globalTrans.address') }}</th>
                                                        <th>{{ $t('globalTrans.date') }}</th>
                                                        <th>{{ $t('globalTrans.price') }}</th>
                                                        <th>{{ $t('globalTrans.collected') }}</th>
                                                        <th>{{ $t('globalTrans.status') }}</th>
                                                        <th>{{ $t('globalTrans.instruction') }}</th>
                                                        <th>{{ $t('globalTrans.age') }}</th>
                                                        <th>Attempt</th>                                       
                                                        <th>{{ $t('globalTrans.action') }}</th>                                       
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(order,key) in orders" :key="key">
                                                        <td>
                                                            <label>
                                                                <input v-if="isSelected" v-model="ordersData" @click="singleSelect(order)" :value="order" type="checkbox" class="order-checkbox" />
                                                                <p>{{ order.id }}</p>
                                                            </label>
                                                        </td>
                                                        <td @click="editModal(key, order)">
                                                            {{ order.merchant.business }} <br/>
                                                            {{ order.merchant.mobile }}
                                                        </td>
                                                        <td>{{ order.name }}, {{ order.mobile }}</td>
                                                        <td>{{ order.address }}</td>
                                                        <td>{{ order.date }}</td>
                                                        <td>{{ order.price }}</td>
                                                        <td>{{ order.collected }}</td>
                                                        <td><span :class="'status-'+order.status">{{ getStatus(order.status) }}</span></td>      
                                                        <td>{{ order.instruction ? order.instruction : '-' }}</td>
                                                        <td>{{ order.age }}</td>       
                                                        <td>{{ order.attempt }}</td>       
                                                        <td>
                                                            <button title="Edit" @click="editModal(key, order)" class="mr-2"><i class="fa fa-edit"></i></button>
                                                            <button title="On Way" @click="onWay(order.id)" v-if="order.status == 9"><i class="fa fa-bicycle"></i></button>
                                                        </td> 
                                                    </tr>
                                                    <infineLoading v-if="spinner" @distance="1" @infinite="loadData"></infineLoading>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>

        <div v-if="orderEditModal" class="modal fade show" style="display:block" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-info text-white">
                        <h5 class="modal-title text-center w-100">Order Details</h5>
                        <button type="button" class="close" aria-label="Close" @click="cancelModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div id="accordion">
                            <div class="card">
                                <div class="card-body">
                                    <b-overlay :show="loading">
                                        <ValidationObserver ref="editForm" v-slot="{ handleSubmit }">
                                            <form @submit.prevent="handleSubmit(updateForm)">
                                                <input type="hidden" v-model="form_edit.id" />
                                                <div class="row">
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                        <ValidationProvider name="Merchant" vid="merchant_id" v-slot="{errors}" rules="required|min_value:1">
                                                            <div class="form-group">
                                                                <label for="merchant_id">Merchant <span class="text-danger" title="Required">*</span></label>
                                                                <select2 v-model="form_edit.merchant_id" placeholder="Select" :options="commonObj.merchantList" disabled></select2>
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                        <ValidationProvider name="Name" vid="name" v-slot="{errors}" rules="required">
                                                            <div class="form-group">
                                                                <label for="name">Name <span class="text-danger" title="Required">*</span></label>
                                                                <input type="text" id="name" v-model="form_edit.name" class="form-control" placeholder="Customer Name" required disabled />
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                        <ValidationProvider name="Mobile" vid="mobile" v-slot="{errors}" rules="required|min:11|max:11">
                                                            <div class="form-group">
                                                                <label for="mobile">Mobile No<span class="text-danger" title="Required">*</span></label>
                                                                <input type="text" id="mobile" v-model="form_edit.mobile" class="form-control" placeholder="Mobile No" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" readonly />
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                        <ValidationProvider name="Alternatvie Mobile" vid="mobile_2" v-slot="{errors}" rules="min:11|max:11">
                                                            <div class="form-group">
                                                                <label for="mobile_2">Alternatvie Mobile</label>
                                                                <input type="text" id="mobile_2" v-model="form_edit.mobile_2" class="form-control" placeholder="Alternatvie Mobile No" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" readonly />
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                        <ValidationProvider name="D" vid="division_id" v-slot="{errors}" rules="required|min_value:1">
                                                            <div class="form-group">
                                                                <label for="division_id">Division <span class="text-danger" title="Required">*</span></label>
                                                                <select2 v-model="form_edit.division_id" placeholder="Select" :options="commonObj.divisionList" disabled></select2>
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                        <ValidationProvider name="District" vid="district_id" v-slot="{errors}" rules="required|min_value:1">
                                                            <div class="form-group">
                                                                <label for="district_id">District <span class="text-danger" title="Required">*</span></label>
                                                                <select2 v-model="form_edit.district_id" placeholder="Select" :options="commonObj.districtList" disabled></select2>
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                        <ValidationProvider name="Thana" vid="thana_id" v-slot="{errors}" rules="required|min_value:1">
                                                            <div class="form-group">
                                                                <label for="thana_id">Thana <span class="text-danger" title="Required">*</span></label>
                                                                <select2 v-model="form_edit.thana_id" placeholder="Select" :options="commonObj.thanaList" disabled></select2>
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                        <ValidationProvider name="Area" vid="area_id" v-slot="{errors}" rules="required|min_value:1">
                                                            <div class="form-group">
                                                                <label for="area_id">Area <span class="text-danger" title="Required">*</span></label>
                                                                <v-select name="area_id"
                                                                    disabled
                                                                    v-model="form_edit.area_id"
                                                                    label="text"
                                                                    :reduce="item => item.id"
                                                                    :options= areaList
                                                                    :placeholder="$t('globalTrans.select')"
                                                                />
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                        <ValidationProvider name="Address" vid="address" v-slot="{errors}" rules="required">
                                                            <div class="form-group">
                                                                <label for="address">Address <span class="text-danger" title="Required">*</span></label>
                                                                <input type="text" id="address" v-model="form_edit.address" class="form-control" placeholder="Address" readonly/>
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                        <ValidationProvider name="Exchange" vid="exchange" v-slot="{errors}" rules="required|min_value:1">
                                                            <div class="form-group">
                                                                <label for="exchange">Exchange <span class="text-danger" title="Required">*</span></label>
                                                                <select2 v-model="form_edit.exchange" :options="exchangeList" disabled></select2>
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                        <ValidationProvider name="Reference ID" vid="ref_id" v-slot="{errors}">
                                                            <div class="form-group">
                                                                <label for="ref_id">Reference ID</label>
                                                                <input type="text" id="ref_id" v-model="form_edit.ref_id" class="form-control" placeholder="Reference ID" readonly />
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                        <ValidationProvider name="Instruction (Note)" vid="instruction" v-slot="{errors}">
                                                            <div class="form-group">
                                                                <label for="instruction">Instruction (Note)</label>
                                                                <input type="text" id="instruction" v-model="form_edit.instruction" class="form-control" placeholder="Instruction (Note)" readonly />
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                        <ValidationProvider name="Category" vid="category_id" v-slot="{errors}" rules="required|min_value:1">
                                                            <div class="form-group">
                                                                <label for="category_id">Category <span class="text-danger" title="Required">*</span></label>
                                                                <select2 v-model="form_edit.category_id" :options="customState.categoryList" disabled></select2>
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                        <ValidationProvider name="Rider" vid="rider_id" v-slot="{errors}">
                                                            <div class="form-group">
                                                                <label for="rider_id">Rider </label>
                                                                <select2 v-model="form_edit.rider_id" :options="commonObj.riderList" disabled></select2>
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                        <ValidationProvider name="Price" vid="price" v-slot="{errors}" rules="required">
                                                            <div class="form-group">
                                                                <label for="price">Price <span class="text-danger" title="Required">*</span></label>
                                                                <input type="text" id="price" v-model="form_edit.price" class="form-control" placeholder="Price" readonly />
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12" v-if="(form_edit.status != 17 && form_edit.status != 18 && form_edit.status != 7 && form_edit.status != 11)">
                                                        <ValidationProvider name="Collected" vid="collected" v-slot="{errors}" rules="required">
                                                            <div class="form-group">
                                                                <label for="price">Collected <span class="text-danger" title="Required">*</span></label>
                                                                <input type="text" id="collected" v-model="form_edit.collected"  @input="checkCollectedAmount" class="form-control" placeholder="Collected" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                        <ValidationProvider name="Status" vid="status" v-slot="{errors}" rules="required|min_value:1">
                                                            <div class="form-group">
                                                                <label for="name">Status <span class="text-danger" title="Required">*</span></label>
                                                                <select id="status" v-model="form_edit.status" @change="statusChange($event)" class="form-control">                                            
                                                                    <option v-for="(status,index) in mappingStatusList" :key="index" :value="status.id">{{ status.text }}</option>
                                                                </select>
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div> 
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12" v-if="isRescheduleDate">
                                                            <ValidationProvider name="Reschedule Date" vid="reschedule_date" v-slot="{errors}">
                                                            <div class="form-group">
                                                                <label for="reschedule_date">Reschedule Date <span class="text-danger" title="Required">*</span></label>        
                                                                <input type="date" v-model="form_edit.reschedule_date" class="form-control" />
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                            </ValidationProvider>
                                                    </div>
                                                    <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12" v-if="isReason">
                                                        <ValidationProvider name="Reason" vid="reason" v-slot="{errors}" rules="required">
                                                            <div class="form-group">
                                                                <label for="reason">Reason <span class="text-danger" title="Required">*</span></label>    
                                                                <v-select name="reason_id"
                                                                    v-model="form_edit.reason_id"
                                                                    label="text"
                                                                    :reduce="item => item.id"
                                                                    :options= commonObj.reasonList
                                                                    :placeholder="$t('globalTrans.select')"
                                                                />
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div> -->
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12" v-if="isReasonText">
                                                        <ValidationProvider name="Reason" vid="reason" v-slot="{errors}" rules="required">
                                                            <div class="form-group">
                                                                <label for="reason">Reason Text <span class="text-danger" title="Required">*</span></label>        
                                                                <input type="text" v-model="form_edit.reason" class="form-control"/>
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12" v-if="isOtpStatus">
                                                        <ValidationProvider name="OTP" vid="otp" v-slot="{errors}" rules="required">
                                                            <div class="form-group">
                                                                <label for="otp">OTP <span class="text-danger" title="Required">*</span></label>        
                                                                <input type="text" v-model="form_edit.otp" class="form-control"/>
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <button type="button" class="btn btn-info btn-sm mr-2 mt-30" @click.prevent="updateForm">Submit</button>
                                                            <button type="reset" class="btn btn-danger btn-sm mt-30" @click="cancelModal">Cancel</button>
                                                        </div>
                                                    </div>
                                                </div> 
                                            </form>
                                        </ValidationObserver>
                                    </b-overlay> 
                                </div>
                                <div class="card-body mt-15">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="name">Order Information</label>
                                                <table class="table table-bordered table-sm">
                                                    <tr>
                                                        <th>Order ID : {{ form_edit.id }}</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Reference ID : {{ form_edit.ref_id }}</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Name : {{ form_edit.name }}</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Mobile : {{ form_edit.mobile }}</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Address : {{ form_edit.address }}</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Instruction : {{ form_edit.instruction }}</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Deadline : {{ form_edit.date }}</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Amount : {{ form_edit.price }}</th>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="name">Payment Details</label>
                                                <table class="table table-bordered table-sm">
                                                    <tr>
                                                        <th>Amount</th>
                                                        <td>{{ form_edit.price }}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Collected </th>
                                                        <td>{{ form_edit.collected }}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Service Charge</th>
                                                        <td>{{ form_edit.service_charge }}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Return Charge</th>
                                                        <td>{{ form_edit.return_charge }}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Area Charge</th>
                                                        <td>{{ form_edit.area_charge }}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Weight Charge</th>
                                                        <td>{{ form_edit.weight_charge }}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>COD Charge</th>
                                                        <td>{{ form_edit.status > 5 ? form_edit.cod : 0 }}</td>
                                                    </tr>
                                                </table>
                                                <button class="btn btn-success btn-sm mr-2" @click="printPos">
                                                    POS <i class="fa fa-print"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="name">Order Activity</label>
                                                <table class="table table-bordered table-sm" style="width:100%">
                                                    <tr>
                                                        <th style="width:20%">Action By</th>
                                                        <th style="width:55%">Action</th>
                                                        <th style="width:25%">Date & Time</th>
                                                    </tr>
                                                    <tr v-for="(log, index) in form_edit.logs" :key="index">
                                                        <td>{{ log.operation_by }}</td>
                                                        <td>{{ log.operation }}</td>
                                                        <td>{{ log.created_at | dateformat }} at {{ log.created_at | timeformat }}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>

        <div v-if="actionPanelModal" class="modal fade show" style="display:block" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-info text-white">
                        <h5 class="modal-title text-center w-100">Rapid Action on Order</h5>
                        <button type="button" class="close" aria-label="Close" @click="cancelModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-xs-12">
                                <div class="form-group">
                                    <label for="name">Scan Barcode</label>
                                    <input type="text" class="form-control" v-model="barcode.sku" @keyup.enter="keepOrder" placeholder="Scan barcode or type order id and press enter"/>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <div class="form-group">
                                    <label for="name">Status</label>
                                    <select id="status" v-model="bulk.status" class="form-control">                                     
                                        <option :value="0">Select</option>
                                        <option v-for="(status, index) in mappingStatusList" :key="index" :value="status.id">{{ status.text }}</option>
                                    </select>
                                    <span class="text-danger">{{ errors[0] }}</span>
                                </div>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="form-group"> 
                                    <span v-for="(order, index) in orderList" :key="index" class="btn btn-sm btn-warning mb-1 mr-1">
                                        {{ order }}<i class="ml-1 fas fa-times" @click="removeRow(index)"></i> 
                                    </span>
                                </div>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="form-group">   
                                    <h6> 
                                        Total : <b>{{ orderList.length != 0 ? orderList.length : this.totalSelected }}</b>                            
                                        <button class="ml-1 btn btn-default btn-sm" @click="scanDone" v-if="isScan">Done <i class="fa fa-check"></i></button>
                                        <slot v-if="loading">
                                            <div class="ml-1 spinner-border spinner-border-sm text-success" role="status"></div>
                                        </slot>
                                        <slot v-else>
                                            <button class="ml-1 btn btn-warning btn-sm" @click="printPos">POS <i class="fa fa-print"></i></button>
                                            <button class="ml-1 btn btn-success btn-sm" @click="actionPanelOrderToPdf">PDF <i class="fa fa-download"></i></button>
                                            <button class="ml-1 btn btn-success btn-sm">
                                                <export-excel
                                                    :data="dataCustomize"
                                                    :fields= "json_fields"
                                                    >
                                                    Excel <i class="fa fa-download"></i>
                                                </export-excel>
                                            </button>
                                            <button v-if="!isScan && bulk.status != 0" class="ml-1 btn btn-info btn-sm" @click="bulkAction">Submit </button>
                                        </slot>
                                    </h6>                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import statusHelper from '@/utils/statusMapping'
    import ExportPdf from './Order.js'
    import infineLoading from 'vue-infinite-loading'
    import config from '@/config'
    import JsBarcode from 'jsbarcode'    
    import moment from 'moment'
    import Pos from './Pos.js'
    import Select2 from 'v-select2-component'
    // import Summary from './Summary.vue'
    const excelColumn = {
        'Order ID': 'order_id',
        'SKU': 'order_sku',
        'Merchant': 'order_merchant_name',
        'Reference ID': 'order_ref_id',
        'Contact Name': 'order_contact_name',
        'Contact Number': 'order_contact_number',
        'Address': 'order_address',
        'Date': 'order_date',
        'Assign Date': 'order_assign_date',
        'Amount': 'order_amount',
        'Collected Amount': 'order_collected',
        'Rider': 'order_rider_name',
        'Status': 'order_status'
    }
    export default {
        name:'Order',
        components: {
            'select2': Select2,
            infineLoading: infineLoading,
        },
        data () {
            return {
                isExcelDownload: false,
                spinner: true,
                loading: false,
                isScan: false,
                isSelected: true,
                selected: [],
                allSelected: false,
                ordersData: [],
                orders:[],
                totalOrder: 0,
                order_id: '',
                printorders:[],
                barcode: {
                    sku: ''
                },
                orderList: [],
                page: 1,
                exchangeList: [
                    { id: 1, text: 'Yes' },
                    { id: 2, text: 'NO' },
                ],
                searchStatusList: [],
                search: {
                    contact : '',
                    sku : '',
                    ref_id: '',
                    status_head: 0,
                    status : [],
                    date_parameter_id  : 4,
                    date_type_id  : 3,
                    start_date    : moment().subtract(90,'d').format('YYYY-MM-DD'),
                    end_date      : moment().format('YYYY-MM-DD')
                },
                form_edit:'',
                errors:[],
                orderEditModal:false,
                actionPanelModal:false,
                rider_id: 0,
                bulk:{
                    order_ids: [],
                    status: 0
                },
                isRescheduleDate: false,
                isReasonText: false,
                isReason: false,
                selected_rows: [],
                totalSelected: 0,
                takeAction: true,
                isOtpStatus: false,
                key:'',
                mappingStatusList: [],
                areaList: [],
                completedStatusIds: [14,15,17,19,20,31],
                inCompletedStatusIds: [7,11,12,13,18,30],
                summary: {
                    total_order: 0,
                    completed: 0,
                    incompleted: 0,
                    total_price: 0,
                    total_collected: 0
                }
            }
        },
        watch: {
            stateReload: function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.searchOrder()
                    this.loadSummary()
                }
            },          
            'form_edit.thana_id': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.areaList = this.getAreaList(newVal)
                }
            },
            'form_edit.reason_id': function (newVal, oldVal) {
                if ((newVal != oldVal) && newVal == 19) {
                    this.isReasonText = true
                }
            }
            // 'form_edit.status': function (newVal, oldVal) {
            //     if (newVal != oldVal) {
            //         this.isOtpStatus = this.checkOtpStatusSelected(newVal)
            //         console.log('this.isOtpStatus = ', this.isOtpStatus)
            //     }
            // }
        },
        created () {
            this.searchOrder()
            this.loadSummary()
        },
        computed : {
            stateReload () {
                return this.$store.state.stateReload
            },
            customState () {
                return this.$store.state
            },
            authUser () {
                return this.$store.state.authUser
            },
            commonObj () {
                return this.$store.state.commonObj
            },
            riderStatusList () {
                return this.$store.state.riderStatusList
            },
            statusList () {
                return this.$store.state.statusList
            },
            json_fields: function () {
                return excelColumn
            },
            dataCustomize () {
                if (this.isExcelDownload) {
                    if (this.printorders.length > 0) {
                        this.printorders.map(item => {
                            item.order_id = item.id
                            item.order_sku = item.sku,
                            item.order_merchant_name= item.merchant_name,
                            item.order_ref_id= item.ref_id,
                            item.order_contact_name= item.name,
                            item.order_contact_number= item.mobile,
                            item.order_address= item.address,
                            item.order_date= item.date,
                            item.order_assign_date= item.assign_date,
                            item.order_amount= item.price,
                            item.order_collected= item.collected,
                            item.order_rider_name= item.rider_name,
                            item.order_status= this.getStatus(item.status)

                            return Object.assign({}, item)
                        })
                        return this.printorders
                    } else if (this.orders.length > 0) {
                        this.orders.map(item => {
                            item.order_id = item.id
                            item.order_sku = item.sku,
                            item.order_merchant_name= item.merchant_name,
                            item.order_ref_id= item.ref_id,
                            item.order_contact_name= item.name,
                            item.order_contact_number= item.mobile,
                            item.order_address= item.address,
                            item.order_date= item.date,
                            item.order_assign_date= item.assign_date,
                            item.order_amount= item.price,
                            item.order_collected= item.collected,
                            item.order_rider_name= item.rider_name,
                            item.order_status= this.getStatus(item.status)

                            return Object.assign({}, item)
                        })
                        return this.orders
                    } else {
                        return []
                    }
                }
                return []
            }
        },
        methods:{
            dateTypeChange () {
                // const dateTypeId = this.search.date_type_id
                const dateTypeId = this.search.date_type_id
                if (dateTypeId == 1) {
                    this.search.start_date = this.search.end_date = moment().format('YYYY-MM-DD')
                } else if (dateTypeId == 2) {
                    this.search.start_date = this.search.end_date = moment().subtract(1,'d').format('YYYY-MM-DD')
                } else if (dateTypeId == 3) {
                    this.search.start_date = moment().subtract(31,'d').format('YYYY-MM-DD')
                    this.search.end_date = moment().format('YYYY-MM-DD')
                } else if (dateTypeId == 4) {
                    this.search.start_date = moment().subtract(180,'d').format('YYYY-MM-DD')
                    this.search.end_date = moment().format('YYYY-MM-DD')
                } else if (dateTypeId == 5) {
                    this.search.start_date = moment().subtract(365,'d').format('YYYY-MM-DD')
                    this.search.end_date = moment().format('YYYY-MM-DD')
                }
            },
            reload () {
                this.search = Object.assign({}, {
                    contact : '',
                    sku : '',
                    ref_id: '',                  
                    status_head: 0,                  
                    status : [],
                    date_parameter_id : 2,
                    date_type_id : 3,
                    start_date : moment().subtract(31,'d').format('YYYY-MM-DD'),
                    end_date : moment().format('YYYY-MM-DD')
                })
                this.searchStatusList = []
                this.orderList = this.ordersData = this.printorders = this.mappingStatusList = []
                this.totalSelected = 0
            },
            selectAll: function() {   
                this.takeAction = false                
                this.ordersData = [];
                this.orderList = [];
                if (!this.allSelected) {
                    for (var order in this.orders) {
                        this.ordersData.push(this.orders[order])
                        this.orderList.push(this.orders[order].id);
                    }
                    this.totalSelected = this.orderList.length
                } else {
                    this.totalSelected = 0
                }
            },
            singleSelect(order) {
                this.takeAction = false
                if (!this.orderList.includes(order.id)) {
                    this.orderList.push(order.id)
                } else {
                    this.orderList.splice(this.orderList.indexOf(order.id), 1)
                }     
                this.totalSelected = this.orderList.length          
                this.allSelected = false;                
            },
            generateBarcode (orderNo) {
                let canvas = document.createElement('canvas');
                JsBarcode(canvas, orderNo, { 
                    format: 'CODE39',
                    displayValue: false,
                    height: 15,
                    width: 1,
                    padding: 0,
                    margin: 0
                });
                return canvas.toDataURL('image/jpeg');
            },
            actionPanelOrderToPdf() {
                const base64Logo = this.getBase64Logo(document.getElementById('logoId'))
                ExportPdf.exportPdfDetails(base64Logo, this.printorders)
            },
            getBase64Logo (logo) {
                var canvas = document.createElement('canvas')
                canvas.width = logo.width
                canvas.height = logo.height
                var ctx = canvas.getContext('2d')
                ctx.drawImage(logo, 0, 0)
                var dataURL = canvas.toDataURL('image/png')
                return dataURL.replace(/^data:image\/(png|jpg);base64,/, '')
            },
            getStatus (status) {
                const tmpStatus = this.statusList.find(tmp => tmp.id == status)
                return typeof tmpStatus != 'undefined' ? tmpStatus.text : status
            },
            getPaymentStatus (paymentStatus) {
                if (paymentStatus === 1) {
                    return 'Due'
                } else if (paymentStatus === 2) {
                    return 'Processing'
                } else if (paymentStatus === 3) {
                    return 'Paid'
                }
            },
            searchOrder() {    
                if (this.search.status.length == 1 ) {
                    this.mappingStatusList = statusHelper.getMappingStatus(this.search.status[0])
                }       
                this.totalOrder = 0
                this.orders = []
                this.page = 1
                this.loadData()
                this.loadSummary()
            },
            loadData($state) {
                this.loading = true
                let vm = this
                const params = Object.assign({}, this.search, { rider_id: this.authUser.id, page: this.page })
                config.getData('rider/order/list', params)
                .then(res => {
                    return res
                }).then(response => {
                    this.loading = false
                    this.totalOrder += response.data.data.length
                    response.data.data.map(item => {
                        vm.orders.push(item)
                    })
                    if (this.totalOrder < response.data.total) {
                        this.spinner = true
                        $state.loaded()
                    } else {
                        this.spinner = false
                    }
                });
                
                this.page = this.page + 1;
            },
            async loadSummary() {
                this.loading = true
                const params = Object.assign({}, this.search, { rider_id: this.authUser.id })
                const response = await config.getData('rider/order/summary', params)
                this.loading = false
                if (response.success) {
                    // this.summary = this.getSummary(response.data)
                    this.summary = response.summary
                } else {
                    this.summary = {
                        total_order: 0,
                        completed: 0,
                        incompleted: 0,
                        total_price: 0,
                        total_collected: 0
                    }
                }
            },
            getSummary (datas) {
                let totalPrice = 0
                let totalCompleted = 0
                let totalInCompleted = 0
                let totalCollected = 0

                datas.map(item => {
                    totalCompleted += this.completedStatusIds.includes(item.status) ? 1 : 0
                    totalInCompleted += this.inCompletedStatusIds.includes(item.status) ? 1 : 0
                    totalPrice += parseFloat(item.price)
                    totalCollected += parseFloat(item.collected)
                })

                const tmpSummary = { 
                    'total_order': datas.length, 
                    'completed': totalCompleted, 
                    'incompleted': totalInCompleted, 
                    'total_price' : totalPrice.toFixed(2), 
                    'total_collected': totalCollected.toFixed(2)
                }
                return Object.assign(this.summary, tmpSummary)
            },
            editModal (index, order) {
                this.mappingStatusList = statusHelper.getMappingStatus(order.status)
                document.body.classList.add("modal-open");
                this.isRescheduleDate = order.reschedule_date != null ? true : false
                this.isReasonText = order.reason != null ? true : false
                this.form_edit =  JSON.parse(JSON.stringify(order))
                this.key = index
                this.printorders.push(this.form_edit)
                this.orderEditModal = true 
                this.isOtpStatus = false 
            },
            async updateForm () {
                this.loading = true
                // this.$store.dispatch('stateReload', true)
                const response = await config.postData('/rider/order/update', this.form_edit)
                this.loading = false
                this.searchOrder()
                if (response.success) {      
                    this.orderEditModal = this.isReasonText = false
                    this.$toast.success({
                        title: 'Success',
                        message: 'Data updated successfully',
                        color: '#218838'
                    }) 
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: response.message,
                        color: '#dc3545'
                    })
                    this.$refs.editForm.setErrors(response.errors)
                }
                // this.$store.dispatch('stateReload', false)
            },
            cancelModal(){                
                this.orderEditModal = this.actionPanelModal =  this.isExcelDownload = false
                this.allSelected = false
                this.barcode.sku = ''
                this.totalSelected = this.bulk.rider_id = 0
                this.printorders = []
                this.ordersData = []
                this.orderList = [] 
                this.mappingStatusList = []   
            },
            async bulkAction(){ 
                this.loading = true 
                if (this.orderList.length > 0) {
                    const orderIdList = this.orderList.filter(val => (val !== undefined) && (val !== null));                     
                    this.bulk.order_ids = []         
                    this.bulk.order_ids = orderIdList
                }
                const skuList = this.barcode.sku.split(" ")
                if (!this.isScan && skuList.length > 0) {
                    skuList.forEach((value) => {
                        const orderId = value.split("-")[2]
                        this.bulk.order_ids.push(orderId)
                    })
                }
                const response = await config.postData('/rider/order/bulk-action', this.bulk)
                this.loading = false 
                if (response.success){   
                    this.orders = response.data
                    this.bulk.status = 0
                    this.$toast.success({
                        title: 'Success',
                        message: response.message,
                        color: '#218838'
                    })
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: response.message,
                        color: '#dc3545'
                    })
                }
            },
            async onWay (orderId) {                 
                this.loading = true
                const params = { id: orderId, rider_id: this.authUser.id }
                const response = await config.postData('/rider/order/on-way', params)
                this.loading = false
                this.loadData()
                if (response.success){   
                    this.$toast.success({
                        title: 'Success',
                        message: response.message,
                        color: '#218838'
                    })
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: response.message,
                        color: '#dc3545'
                    })
                }
            },
            showActionPanelModal () {
                this.isScan = true
                this.actionPanelModal = true;
            },
            keepOrder() { 
                let skuList = this.barcode.sku.split(" ")
                if(skuList.length > 1) {
                    skuList.map(item => {
                        this.orderList.push(item);
                    })
                } else {
                    this.orderList.push(this.barcode.sku); 
                }
                this.isScan = true  
                this.barcode.sku = ''
            },
            async scanDone () {
                this.loading = true
                const response = await config.postData('/rider/order/scaned-order', { 'order_ids': this.orderList })                        
                this.loading = false                                          
                this.isScan = false                                          
                if (response.success) {                                           
                    this.printorders = response.data;
                    this.isExcelDownload = true
                } else {
                    this.$toast.error('Order not found') 
                }
            },
            removeRow(index){
                this.isScan = true
                this.orderList.splice(index, 1)
            },
            printPos() {
                const base64Logo = this.getBase64Logo(document.getElementById('logoId'))
                Pos.expostPosDetails(base64Logo, this.printorders)
            },
            getAreaList (thanaId) {
                return this.commonObj.areaList.filter(el => el.thana_id == thanaId)
            },
            statusChange (event) {
                this.isRescheduleDate = this.isReasonText = false
                var status = event.target.value
                this.form_edit.collected = (status == 14 || status == 19) ? this.form_edit.price : 0
                if (status == 18) {
                    this.isRescheduleDate = true
                    this.isReasonText = true
                } else if ((status == 12 || status == 13 || status == 30) || (status >= 16 && status <= 20)) {
                    this.isReasonText = true
                } else {
                    this.isReasonText = false
                }
                this.isOtpStatus = false
                this.checkOtpStatusSelected(status)
            },
            checkCollectedAmount () {
                this.isReasonText = (parseFloat(this.form_edit.price) != parseFloat(this.form_edit.collected)) ? true : false                
            },
            async checkOtpStatusSelected (status) {
                // let authUserOtpStatus = this.authUser.otp_status.substring(1, this.authUser.otp_status.length - 1);
                // let otpStatusList = authUserOtpStatus.split(',');
                let newStatus = parseInt(status)
                if(this.authUser.otp_status.includes(newStatus)) {
                    this.loading = true
                    const response = await config.postData('/rider/order/otp-set', this.form_edit)
                    this.loading = false
                    if (response.success) {      
                        this.$toast.success({
                            title: 'Success',
                            message: response.message,
                            color: '#218838'
                        }) 
                        this.isOtpStatus = true
                    }
                } else {
                    this.isOtpStatus = false
                }
            }
        }
    }
</script>
<style scoped>
    @media print{
        body {
            margin-left:-10px;
        }
    }
    .mt-30 {
        margin-top: 30px !important;
    }

    @media only screen and (max-width: 760px),
	(min-device-width: 768px) and (max-device-width: 1024px)  {
	
		/* Force table to not be like tables anymore */
		table, thead, tbody, th, td, tr { 
			display: block; 
		}

        .table-sm th, .table-sm td {
            padding: 0.3rem;
            padding-left: 50%;
        }
		
		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr { 
			position: absolute;
			top: -9999px;
			left: -9999px;
		}
		
		tr { 
            border: 1px solid #ccc;
            margin-bottom: 7px; 
        }
		
		td { 
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee; 
			position: relative;
			padding-left: 50%; 
		}
		
		td:before { 
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 6px;
			left: 6px;
			width: 45%; 
			padding-right: 10px; 
			white-space: nowrap;
		}
		
		/*
		Label the data
		*/
		td:nth-of-type(1):before { content: "Order ID" }
		td:nth-of-type(2):before { content: "Merchant"; }
		td:nth-of-type(3):before { content: "Customer"; }
		td:nth-of-type(4):before { content: "Address"; }
		td:nth-of-type(5):before { content: "Date"; }
		td:nth-of-type(6):before { content: "Price"; }
		td:nth-of-type(7):before { content: "Collected"; }
		td:nth-of-type(8):before { content: "Status"; }
		td:nth-of-type(9):before { content: "Instruction"; }
		td:nth-of-type(10):before { content: "Age (days)"; }
		td:nth-of-type(11):before { content: "Attempt"; }
		td:nth-of-type(12):before { content: "Action"; }
	}
	
	/* Smartphones (portrait and landscape) ----------- */
	@media only screen
	and (min-device-width : 320px)
	and (max-device-width : 480px) {
		body { 
			padding: 0; 
			margin: 0; 
			width: 320px; }
		}
	
	/* iPads (portrait and landscape) ----------- */
	@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		body { 
			width: 495px; 
		}
	}
</style>