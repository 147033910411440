import Store from '@/store'
// import i18n from '@/i18n'

function getMappingStatus (status) {

    const statusList = Store.state.statusList    
    let tmpStatusList = []

    if (status == 1) {
        tmpStatusList = statusList.filter(item => item.id == 2 || item.id == 3)
    } else if (status == 3) {
        tmpStatusList = statusList.filter(item => item.id == 4)
    } else if (status == 4) {
        tmpStatusList = statusList.filter(item => item.id == 9 || item.id == 10)
    } else if (status == 9) {
        tmpStatusList = statusList.filter(item => item.id == 11)
    } else if (status == 10) {
        tmpStatusList = statusList.filter(item => item.id == 5)
    } else if (status == 5) {
        tmpStatusList = statusList.filter(item => item.id == 6 || item.id == 7)
    } else if (status == 7) {
        tmpStatusList = statusList.filter(item => item.id == 11)
    } else if (status == 11) {
        tmpStatusList = statusList.filter(item => item.id == 14 || item.id == 15 || item.id == 13 || item.id == 17 || item.id == 18 || item.id == 19 || item.id == 20 || item.id == 12 || item.id == 30)
    } else if (status == 17) {
        tmpStatusList = statusList.filter(item => item.id == 7 ||item.id == 18)
    } else if (status == 18) {
        tmpStatusList = statusList.filter(item => item.id == 11)
    } else if (status == 20) {
        tmpStatusList = statusList.filter(item => item.id == 21 || item.id == 25)
    } else if (status == 21) {
        tmpStatusList = statusList.filter(item => item.id == 22)
    } else if (status == 22) {
        tmpStatusList = statusList.filter(item => item.id == 23)
    } else if (status == 23) {
        tmpStatusList = statusList.filter(item => item.id == 24)
    } else if (status == 24) {
        tmpStatusList = statusList.filter(item => item.id == 25)
    } else if (status == 16) {
        tmpStatusList = statusList.filter(item => item.id == 14)
    } else if (status == 31) {
        tmpStatusList = statusList.filter(item => item.id == 21)
    }

    return tmpStatusList
}

export default {
    getMappingStatus,
}
