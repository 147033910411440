import JsBarcode from 'jsbarcode' 
import Store from '@/store'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_pdf_kal_fonts_bn'

function getOrderStatus (status) {
    const tmpStatus = Store.state.statusList.find(el => el.id == status)
    return tmpStatus.text
}

function getPaymentStatus (status) {
    if (status == 0) {
        return ''
    } else if (status == 1) {
        return 'Due'
   } else if (status == 2) {
        return 'Processing'
   } else if (status == 3) {
        return 'Paid'
   }
}

const exportPdfDetails = async (base64Logo, vm) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })

    pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    pdfMake.fonts = {
      Kalpurush: {
        normal: 'Kalpurush.ttf',
        bold: 'Kalpurush.ttf',
        italics: 'Kalpurush.ttf',
        bolditalics: 'Kalpurush.ttf'
      }
    }

    const pdfContent = [
      { image: 'data:image/png;base64,' + base64Logo, height: 70, width: 70, style: 'logo', alignment: 'left' },
      { text: 'Email: support@bahokcourier.com', style: 'org', alignment: 'left' },
      { text: 'Total Order : ' + vm.length, style: 'org', alignment: 'right' }
    ]

    pdfContent.push({ text: '', style: 'space' })

    const allRows = [
      [
        { text: 'Barcode', style: 'th', alignment: 'center', bold: true },
        { text: 'Order Info', style: 'th', alignment: 'center', bold: true },
        { text: 'Merchant', style: 'th', alignment: 'center', bold: true },
        { text: 'Customer', style: 'th', alignment: 'center', bold: true },
        { text: 'Address', style: 'th', alignment: 'center', bold: true },
        { text: 'Area', style: 'th', alignment: 'center', bold: true },
        { text: 'Price', style: 'th', alignment: 'center', bold: true },
        { text: 'Collected', style: 'th', alignment: 'center', bold: true },
        { text: 'Status', style: 'th', alignment: 'center', bold: true },
        { text: 'Payment', style: 'th', alignment: 'center', bold: true },
        { text: 'Instruction', style: 'th', alignment: 'center', bold: true }
      ]
    ]

    vm.forEach(item => {
        const orderInfo = item.tracking_id + ', Date:' + item.date + ', ' + 'Ref:'+ (item.ref_id ? 'Ref:'+ item.ref_id : '')
        const merchant = item.merchant.business + ' \n ' + item.merchant.mobile
        const customer = item.name + ' \n ' + item.mobile
        const orderStatus = getOrderStatus(item.status)
        const paymentStatus = getPaymentStatus(item.payment_status)

        allRows.push([
            { image: 'data:image/' + generateBarcode(item.id), alignment: 'center', style: 'barCodeTd' },
            { text: orderInfo, alignment: 'left', style: 'td' },
            { text: merchant, alignment: 'left', style: 'td' },
            { text: customer, alignment: 'left', style: 'td' },
            { text: item.address, alignment: 'left', style: 'td' },
            { text: item.area.name, alignment: 'left', style: 'td' },
            { text: item.price, alignment: 'center', style: 'td' },
            { text: item.collected, alignment: 'center', style: 'td' },
            { text: orderStatus, alignment: 'left', style: 'td', color: [243,198,77] },
            { text: paymentStatus, alignment: 'left', style: 'td' },
            { text: item.instruction, alignment: 'left', style: 'td' }
        ])
    })

    pdfContent.push({
        table: {
            headerRows: 1,
            widths: ['15%', '13%', '8%', '8%', '12%', '8%', '5%', '5%', '10%', '6%', '10%'],
            body: allRows
        }
    })

    var docDefinition = {
      content: pdfContent,
      pageSize: 'A4',
      pageOrientation: 'landscape',
      footer: function (currentPage, pageCount) {
        return {
          table: {
            body: [
                [
                  { text: "Page " + currentPage.toString() + ' of ' + pageCount, alignment: 'left', style: 'pageNo', /*margin: [0, 0, 0, 0]*/ }
                ]
            ]
          }
        };
      },
      styles: {
        th: {
          fontSize: 8,
          margin: [3, 3, 3, 3]
        },
        td: {
          fontSize: 8,
          margin: [0, 0, 0, 0]
        },
        barCodeTd: {
          fontSize: 8,
          margin: [0, 3, 0, 0]
        },
        space: {
          margin: [5, 0, 0, 5]
        },
        header: {
          fontSize: 17,
          bold: true,
          margin: [0, 5, 0, 0]
        },
        logo: {
          margin: [0, -30, 0, 0]
        },
        pageNo: {
            fontSize: 10,
            margin: [30, 0, 0, 0]
        },
        hh: {
          fontSize: 13,
          bold: true,
          margin: [10, 10, 25, 20]
        }
      }
    }
    pdfMake.createPdf(docDefinition, null, null, null).print()
  } catch (error) {
    if (error) {
      console.log('err => ', error)
    }
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}

function generateBarcode (orderNo) {
  let canvas = document.createElement('canvas');
  JsBarcode(canvas, orderNo, { 
      format: 'CODE39',
      displayValue: false,
      height: 15,
      width: 1,
      padding: 0,
      margin: 0
  });
  return canvas.toDataURL('image/png');
}

export default {
    exportPdfDetails
}
